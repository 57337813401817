import React from 'react';
import { Redirect } from '@reach/router';

import { Layout } from '../components';
import SEO from '../components/seo';

class IndexPage extends React.Component {
    state = { loading: false, msg: null };

    handleClick = (e) => {
        e.preventDefault();

        this.setState({ loading: true });
        fetch('/.netlify/functions/token-hider')
            .then((response) => response.json())
            .then((json) => this.setState({ loading: false, msg: json.message }));
    };

    render() {
        const { loading, msg } = this.state;
        return (
            <Layout>
                <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
                <Redirect to="/app/login" />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                ></div>
            </Layout>
        );
    }
}

export default IndexPage;
